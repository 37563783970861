import React from "react"
import { Container, Row, NavLink } from "react-bootstrap"

class Footer extends React.Component {
  componentDidMount() {

  }
  render() {
    return (
      <footer>
        <Container className="text-center">
          <Row>
            <div className="col-sm">
              <NavLink href="tel:+7%20(812)%20614%2004%2003"><span className="icon-call-out" />
                <br />+7 (812) 614 04 03</NavLink>
            </div>
            <div className="col-sm">
              <NavLink href="mailto:rbm@ispolinia.ru"><span className="icon-envelope" />
                <br />rbm@ispolinia.ru</NavLink>
            </div>
          </Row>
        </Container>

      </footer>
    )
  }
}

export default Footer