import React from "react"
import $ from "jquery"

import { Link } from "gatsby"
import logoSrc from "../images/logo.gif"
import { Nav, Navbar, NavbarBrand, NavItem } from "react-bootstrap"
import { Helmet } from "react-helmet"
import logo from "../images/og-logo.png"
import { YMInitializer } from "react-yandex-metrika"

let chatReady = false


function highlight() {
  $(document).ready(function() {
    $(".navbar-nav .active").each(function() {
      $(this).removeClass("active")
    })

    $(".navbar-nav .nav-item [href]").each(function() {
      if (this.href === window.location.href || this.href + "/" === window.location.href) {
        $(this).addClass("active")
      }
    })
  })
}

function menuIcon() {
  if ($(".icon-menu").length !== 0) $(".icon-menu").addClass("icon-arrow-up").removeClass("icon-menu")
  else {
    $(".icon-arrow-up").addClass("icon-menu").removeClass("icon-arrow-up")
  }
}

class Header extends React.Component {

  componentDidMount() {

    highlight()
    if (!chatReady) {
      (function(w, d, u) {
        var s = d.createElement("script")
        s.async = true
        s.src = u + "?" + (Date.now() / 60000 | 0)
        var h = d.getElementsByTagName("script")[0]
        h.parentNode.insertBefore(s, h)
      })(window, document, "https://cdn-ru.bitrix24.ru/b10910472/crm/site_button/loader_3_z2jb61.js")
      chatReady = true
    }
  }

  render() {
    return (
      <header>
        <YMInitializer accounts={[82370695]} options={{ defer: true }} />

        <Helmet htmlAttributes={{
          lang: "ru"
        }}>
          <meta name="theme-color" content="#b50f0c" />
          <meta property="og:image" content={logo} />
          <meta property="og:locale" content="ru_RU" />
          <meta property="og:site_name" content="Исполиния" />
          <meta name="robots" content="index, follow" />

        </Helmet>

        <Navbar collapseOnSelect expand="xl">
          <div className="navbar container-md" id="top">
            <NavbarBrand><Link to="/"><img id="logo" width="337" src={logoSrc}
                                           alt="ИспоЛиния" /></Link></NavbarBrand>
            <Navbar.Toggle onClick={menuIcon} aria-controls="navbarNav"><span className="icon-menu" /></Navbar.Toggle>
            <Navbar.Collapse id="navbarNav">
              <Nav>
                <NavItem>
                  <Link className="nav-link company" to="/company">КОМПАНИЯ</Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link production" to="/production">ПРОИЗВОДСТВО</Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link products" to="/products">ПРОДУКЦИЯ</Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link cooperation" to="/cooperation">СОТРУДНИЧЕСТВО</Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link information" to="/info">ИНФОРМАЦИЯ</Link>
                </NavItem>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>
    )
  }
}

export default Header